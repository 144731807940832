import React from "react";
import "./Header.scss"
import crown from "../../assets/crown-solid.svg";

const Header = () => {
  return (
    <header>
      <div className="header-cont center">
        <a href="/">
          <img src={crown} alt="logo" />
          <span>SUGAR SUPREME</span>
        </a>
      </div>
    </header>
  );
};

export default Header;
