import React from "react";
import "./Info.scss";
import mainImg from "../../assets/main-img.webp";
import win1 from "../../assets/win1.jpg";
import rating from "../../assets/5star2.png";
import star from "../../assets/star-solid.svg";

const Info = () => {
  return (
    <div className="info">
      <div className="info-cont center">
        <div className="info-cont-left">
          <div className="info-cont-left-img">
            <h1>Sugar Supreme Powernudge</h1>
            <img src={mainImg} alt="" />
          </div>
          <h2>Play Sugar Supreme</h2>
          <div className="info-cont-left-promo">
            <div>
              <img src={win1} alt="1win" />
              <div>
                <p>1 Win</p>
                <div>
                  <img src={star} alt="star" />
                  <img src={star} alt="star" />
                  <img src={star} alt="star" />
                  <img src={star} alt="star" />
                  <img src={star} alt="star" />
                </div>
              </div>
              <div>
                <ul>
                  <li>500% bonus</li>
                  <li>70 freespins</li>
                  <li>Cashback 30%</li>
                </ul>
              </div>
              <a href="https://1wiaxy.xyz/#79x5" target="_blank">
                <button>PLAY</button>
              </a>
            </div>
          </div>
          <p>
            In the newest Pragmatic slot filled with sweets, the Powernudge
            mechanics are in the spotlight: the Multiplier spot feature from the
            Sugar Rush demo is complemented by the Powernudge sprinklers
            feature, which we are already familiar with from the Sweet
            Powernudge demo! We can't wait to learn more about the gameplay and
            winning features of this online slot, because it has two very juicy
            features!
          </p>
        </div>
        <div className="info-cont-right">
          <h1>About slot</h1>
          <div className="info-cont-right-stats">
            <p>Provider:</p>
            <p>Pragmatic Play</p>
            <p>RTP:</p>
            <p>96.09%</p>
            <p>Max. Win:</p>
            <p>5000x</p>
            <p>Lines:</p>
            <p></p>
            <p>Grid:</p>
            <p>6x6</p>
            <p>Functions:</p>
            <p>Powernudge Feature, Free Spins, Buy Free Spins</p>
          </div>
          <div className="info-cont-right-rating">
            <img className="raiting-stars" src={rating} alt="rating" />
            <p>5  </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
