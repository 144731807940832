import React from "react";
import "./Games.scss";
import img1 from "../../assets/fortunes-of-aztec.png"
import img2 from "../../assets/8-golden-dragon-challenge.png"
import img3 from "../../assets/gold-oasis.png"
import img4 from "../../assets/rainbow-reels.png"
import img5 from "../../assets/gravity-bonanza.png"
import img6 from "../../assets/twilight-princess.png"

const Games = () => {
  return (
    <div className="games">
      <div className="games-cont center">
        <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <img src={img1} alt="img" />
            <p>Fortunes of Aztec</p>
        </a>
        <a href="https://izzicasino.life/cf91e09c3" target="_blank">
            <img src={img2} alt="img" />
            <p>8 Golden Dragon Challenge</p>
        </a>
        <a href="https://colorful-road-three.com/d35db545f" target="_blank">
            <img src={img3} alt="img" />
            <p>Gold Oasis</p>
        </a>
        <a href="https://nice-road-two.com/d74076b1c" target="_blank">
            <img src={img4} alt="img" />
            <p>Rainbow Reels</p>
        </a>
        <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
            <img src={img5} alt="img" />
            <p>Gravity Bonanza</p>
        </a>
        <a href="https://vavadapartners.pro/?promo=9e1e1391-392b-4e33-87c7-a3523df5e8cf&target=register" target="_blank">
            <img src={img6} alt="img" />
            <p>Twilight Princess</p>
        </a>
      </div>
    </div>
  );
};

export default Games;
