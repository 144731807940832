import "./App.css";
import Footer from "./components/footer/Footer";
import Games from "./components/games/Games";
import Header from "./components/header/Header";
import Info from "./components/info/Info";

function App() {
  return (
    <>
      <div className="cont">
        <Header />
        <Info />
        <Games />
      </div>
      <Footer />
    </>
  );
}

export default App;
