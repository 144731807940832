import React, { useEffect, useState } from "react";
import "./Footer.scss"

const Footer = () => {

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(intervalId);
  }, []);

  return (
    <footer>
      <div className="footer-cont center">
        <p>© {currentYear} sugar-supreme.com</p>
      </div>
    </footer>
  );
};

export default Footer;
